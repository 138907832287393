import { FbCreateNewGroup, FbGetAdGroup } from '../api'
import { ref } from 'vue'
const groupName = ref('')
const desc = ref('')
const isProcessing = ref(false)

const isGroupLoading = ref(false)
const groupLists = ref([])

export default (selectedAccount, selectedGroup) => {
  const createGroup = async (accountId) => {
    try {
      if (groupName.value === '') {
        window.alert('Audience Name Required')
        return
      }
      isProcessing.value = true
      const { Statuscode } = await FbCreateNewGroup({
        orgID: 10,
        uid: 10,
        ADID: accountId,
        can: groupName.value,
        cad: desc.value,
        type: "email"
      })
      if (Statuscode === '200') window.alert('Success')
      if (Statuscode !== '200') throw new Error()
      groupName.value = ''
      desc.value = ''
      isProcessing.value = false
    } catch (err) {
      isProcessing.value = false
      console.error(err)
      throw new Error()
    }
  }

  const fetchGroupLists = async () => {
    try {
      if (selectedAccount.value === '') {
        window.alert('Account Required')
        return
      }
      groupLists.value = []
      selectedGroup.value = ''
      isGroupLoading.value = true
      const { Statuscode, data } = await FbGetAdGroup(selectedAccount.value)
      isGroupLoading.value = false
      groupLists.value = data || []
      if (Statuscode !== '200') throw new Error()
    } catch (err) {
      isGroupLoading.value = false
      console.error(err)
      window.alert('Fail, Can not get audiences')
    }
  }

  const handleCreateGroup = async () => {
    try {
      await createGroup(selectedAccount.value)
      fetchGroupLists()
    } catch (err) {
      console.error(err)
      window.alert('Fail, Can not create audience')
    }
  }
  return {
    groupName,
    desc,
    isProcessing,
    isGroupLoading,
    groupLists,
    fetchGroupLists,
    handleCreateGroup,
  }
}

const url = "https://connect.accunix.net";
export const FbCheckLoginStatus = () => {
  return fetch(url + "/GADs/check_status.php?orgID=10&uid=10&debug=Y").then((res) => {
    if (res.status !== 200) throw new Error();
    return res.json();
  });
};
export const FbGetAdAccount = () => {
  return fetch(url + "/GADs/get_ADList.php?orgID=10&uid=10").then((res) => {
    if (res.status !== 200) throw new Error();
    return res.json();
  });
};
export const FbGetAdGroup = (ADID) => {
  return fetch(url + `/GADs/get_CSList.php?orgID=10&uid=10&ADID=${ADID}`).then(
    (res) => {
      if (res.status !== 200) throw new Error();
      return res.json();
    }
  );
};
export const FbCreateNewGroup = ({ orgID, uid, ADID, can, cad , type }) => {
  return fetch(url + "/GADs/add_CSList.php", {
    method: "post",
    body: JSON.stringify({
      orgID,
      uid,
      ADID,
      can,
      cad,
      type,
    }),
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  }).then((res) => {
    if (res.status !== 200) throw new Error();
    return res.json();
  });
};

export const FbAdSend = ({ aid }) => {
  return fetch(url + "/GADs/add_CSData.php", {
    method: "post",
    body: JSON.stringify({
      orgID: "10",
      uid: "10",
      adid: "3998601614",
      type: "email",
      aid,
      fpc: [
        "michliu.liu@gmail.com","**go0829@hotmail.com","+sansan1021@hotmail.com","00000211@yam.com","0000031@mail.water.gov.tw","000004jenny@laforme.com.tw","000008@mail.com.tw","000055@mail.moex.gov.tw","000126@MAIL.MOEX.GOV.TW","0002458@auto.com","000508ret@gmail.com","0006@cpc.tw","00071@go.msps.tp.edu.tw","000808ret@gmail.com","000826@mail.sogo.com.tw","0008857@gmail.com","00093shuli@gmail.com","0009422@auo.com","000991@sogo.com.tw","000maggie000@gmail.com","000yju@gmail.com","001008@yahoo.com.tw","0013angela@gmail.com","0013ccchen@gmail.com","00140@mail.com.tw","001493chiou@gmail.com.tw","0014steve@gmail.com","00168.hi@gmail.com","001723@mail.sogo.com.tw","001733@webmail.customs.gov.tw","00173510s@gmail.com","00180ret@gmail.com","001901ret@gmail.com","0019765@rail.gov.tw","001kimchi@gmail.com","001lin@yahoo.com.tw","00200303402@gmail.com","002112@twfhc.com.tw","0022.kitty@gmail.com","002227@yahoo.com.tw","002356@mail.sogo.com.tw","0023aj@yahoo.com.tw","00243@ms.bli.gov.tw","00257211@email.ntou.edu.tw","002640@mail.fju.edu.tw","00267@ms.bli.gov.tw","002680@ubos.com.tw","0027584@railway.gov.ew","002770@ubos.com.tw","0029331@sks.com.tw","003037@megabank.com.tw","003061@tcb-bank.com","003062@gmail.com","0032727@gmail.com","0033sr@gmail.com","0034330@gmail.com","003928@webmail.customs.gov.tw","004024ret@gmail.com","004071@mail.fju.edu.tw","004107ret@gmail.com","004557ret@gmail.com","0045cat@gmail.com","004684@ntuh.gov.tw"
      ],
    }),
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  }).then((res) => {
    if (res.status !== 200) throw new Error();
    return res.json();
  });
};
